import React from "react"
import { Link } from "gatsby"
import '../styles/utilitary.css'
import logo from "../images/logo.png" // Tell webpack this JS file uses this image
import '../styles/footer.css'
import certificacao from "../images/certificacao.png" // Tell webpack this JS file uses this image
import { MdCall } from "react-icons/md";
import scrollTo from 'gatsby-plugin-smoothscroll';









const Footer = () => {
    return(
        <footer>
              <div id="footer-section" >
              <div className="wrapper-contentorgeral-2">
                  <div className="col-left">
                        <div className="logo2">
                        <img  src={certificacao} alt="" />

                        </div>
                    </div>
                    <div className="col-left">
                        <div className="logo">
                        <img  src={logo} alt="" />

                        </div>
                    </div>
              
                    
                  </div>
                  <div className="wrapper-contentorgeral">
                    <div className ="left-col">
                        <div className="titulo inter-bold">
                            <p>Navegação</p>
                        </div>
                        <div className="lista-links inter-thin">
                        <a className="hover" onClick={() => { scrollTo('#section-2');  }}> Bovinos de Carne</a><br></br>
                <a className="hover" onClick={() =>{ scrollTo('#section-3'); } }> Bovinos de Leite</a><br></br>
                <a className="hover" onClick={() =>{ scrollTo('#section-4'); }}> Aves</a><br></br>
                <a className="hover" onClick={() => { scrollTo('#section-5'); }} > Pequenos Ruminantes</a><br></br>
                <a className="hover" onClick={() => { scrollTo('#section-6'); }} > Suínos</a><br></br>
                <a className="hover" onClick={() =>{ scrollTo('#section-7'); } }> Coelhos</a><br></br>



                        </div>

                    </div>
                    <div className ="center-col">
                    <div className="titulo inter-bold">
                            <p>Social</p>
                        </div>
                        <div className="lista-links inter-thin">
                          <a className="hover" href="https://www.facebook.com/farminofficial" target="_blank"><span>Facebook</span><br></br></a>
                          <a className="hover" href="https://www.instagram.com/farmin.trainings/" target="_blank"><span>Instagram</span><br></br></a>
                          <a className="hover" href="                          https://www.linkedin.com/company/farmin-livestock-trainings/about/
" target="_blank"><span>LinkedIn</span></a>

                        </div>
                    </div>
                    <div className ="center2-col">
                    <div className="titulo inter-bold">
                            <p>Informação</p>
                        </div>
                        <div className="lista-links inter-thin">
                        <span>
                            Termos e Condições
                        </span> <br></br>    
                        <span>
                            Política de Privacidade
                        </span>
                   </div>
                        </div>
                    <div className ="right-col">
                    
                    <div className="titulo inter-bold">
                            <p>Suporte</p>
                        </div>
                        <div className="button-call">
                            <button><MdCall/> <a href="tel:+351914793351">Entrar em Contacto</a>  <div className=" inter-thin">
                            <span>(rede móvel nacional)</span>
                            </div></button>
                           
                        </div>
                        <div className="lista-links-email inter-bold">

                        <span>
                        farmin@farmin-trainings.net
                        </span> <br></br>    
                        </div>
                        <div className="lista-links inter-thin">

                        <span>
                        
                        +351 914 793 351 (rede móvel nacional)
                        </span><br></br>
                        <span>Edifício Caçabrava S/N<br></br>

2305-101 Asseiceira, Tomar<br></br>

Portugal</span>
                   </div>

                    </div>
                  </div>
                  <div className="wrapper-contentorgeral-2">
               
                    <div className="col-right">
                    <div className="desc">
                    <span ><h1 className="titulo-rodape inter-thin">FarmIN - Livestock Trainings © 2023</h1></span>
                    <span className="inter-thin handcrafted">Handcrafted by <a className="link-40 inter-bold" target="_blank" href="https://www.fortydegrees.pt">40º</a></span>

                    </div>
                    </div>
                    
                  </div>


    
              </div>
      

        </footer>
    )


}

export default Footer